<template>
  <v-card>
    <v-card-title class="text-h6">
      CTKM: {{ item.name }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="4">
          <div class="d-flex">
            <div class="fs-13px">Áp dụng:</div>
            <div class="ml-2 black--text fs-13px">
              <div v-html="item.accepted_pos" style="white-space: pre"></div>
            </div>
          </div>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="4">
              <div>
                <span class="d-inline-block fs-13px" style="width: 60px"
                  >Bắt đầu:</span
                >
                <span class="black--text fs-13px">{{
                  formatDateTime(item.start_time)
                }}</span>
              </div>
              <div>
                <span class="d-inline-block fs-13px" style="width: 60px"
                  >Kết thúc:</span
                >
                <span class="black--text fs-13px">{{
                  formatDateTime(item.end_time)
                }}</span>
              </div>
            </v-col>
            <v-col cols="4">
              <div>
                <span class="d-inline-block fs-13px" style="width: 60px"
                  >Kiểu KM:</span
                >
                <span class="black--text fs-13px">{{
                  item.promotion_type_text
                }}</span>
              </div>
              <div>
                <span class="d-inline-block fs-13px" style="width: 60px"
                  >SP sale:</span
                >
                <span class="black--text fs-13px">{{
                  item.is_discount_sale_product ? "Áp dụng" : "Không"
                }}</span>
              </div>
            </v-col>
            <v-col cols="4">
              <div>
                <span class="d-inline-block fs-13px" style="width: 60px"
                  >T.Gian:</span
                >
                <span class="fs-13px" :class="item.program_color">{{
                  item.program_text
                }}</span>
              </div>
              <div>
                <span class="d-inline-block fs-13px" style="width: 60px"
                  >T.Thái:</span
                >
                <span class="black--text fs-13px">{{
                  item.status ? "Active" : "Inactive"
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="item.promotion_type === 1">
      <v-simple-table class="table-padding-4 mb-3">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.bill_from") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.bill_to") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.discount_by") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.reduce") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center">Đã sử dụng</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(pbItem, pbIndex) in promotionBills"
              :key="`pb_${pbItem.id}_${pbIndex}`"
            >
              <td class="text-center">
                {{ formatNumber(pbItem.discount_from) }}
              </td>
              <td class="text-center">
                {{ formatNumber(pbItem.discount_to) }}
              </td>
              <td class="text-center">{{ pbItem.discount_type_text }}</td>
              <td class="text-center">{{ pbItem.discount_value_text }}</td>
              <td class="text-center">{{ formatNumber(pbItem.limit) }}</td>
              <td class="text-center">{{ formatNumber(pbItem.used) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else-if="item.promotion_type === 2">
      <v-simple-table class="table-padding-4 mb-3">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.promotion_code") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">Tên SP</th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Số lượng (Từ)
              </th>
              <th role="columnheader" class="text-center">Giá sale 01 sp</th>
              <th
                v-if="item.promotion_type === 2"
                role="columnheader"
                class="text-center"
                style="width: 105px"
              >
                Nguyên giá 01 sp
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Tổng giá bán lẻ
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Giảm %
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Giảm tiền
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ppItem, ppIndex) in promotionProducts"
              :key="`pp_${ppItem.id}_${ppIndex}`"
            >
              <td class="text-center">{{ ppItem.code }}</td>
              <td class="text-center">{{ ppItem.customer_goods_barcode }}</td>
              <td class="text-center">{{ ppItem.goods_name }}</td>
              <td class="text-center">{{ ppItem.discount_from }}</td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_value) }}
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.one_origin_price) }} đ
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.origin_price) }} đ
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_percent * 100, 2) }} %
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_price) }} đ
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else-if="item.promotion_type === 3">
      <v-simple-table class="table-padding-4 mb-3">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.promotion_code") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Số lượng
              </th>
              <th role="columnheader" class="text-center">Giá sale 01 sp</th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Tổng giá bán lẻ
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Tổng giá sale
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Giảm %
              </th>
              <th role="columnheader" class="text-center" style="width: 105px">
                Giảm tiền
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ppItem, ppIndex) in promotionCombos"
              :key="`pp_${ppItem.id}_${ppIndex}`"
            >
              <td class="text-center">{{ ppItem.code }}</td>
              <td class="text-center">
                <div
                  v-for="(pItem, pIndex) in ppItem.items"
                  :key="`pib_${ppItem.id}_${ppIndex}_${pIndex}`"
                  :class="{ 'mb-1': ppItem.items.length > 1 }"
                >
                  {{ pItem.customer_goods_barcode }}
                </div>
              </td>
              <td class="text-center">
                <div
                  v-for="(pItem, pIndex) in ppItem.items"
                  :key="`piq_${ppItem.id}_${ppIndex}_${pIndex}`"
                  :class="{ 'mb-1': ppItem.items.length > 1 }"
                >
                  {{ pItem.quantity }}
                </div>
              </td>
              <td class="text-center">
                <div
                  v-for="(pItem, pIndex) in ppItem.items"
                  :key="`piq_${ppItem.id}_${ppIndex}_${pIndex}`"
                  :class="{ 'mb-1': ppItem.items.length > 1 }"
                >
                  {{ formatNumber(pItem.price_per_item) }}
                </div>
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.origin_price) }} đ
              </td>
              <td class="text-center font-weight-medium error--text">
                {{ formatNumber(ppItem.discount_value) }} đ
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_percent * 100, 2) }} %
              </td>
              <td class="text-center">
                {{ formatNumber(ppItem.discount_price) }} đ
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text v-else-if="item.promotion_type === 4">
      <v-simple-table class="table-padding-4 mb-3">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">UID</th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">Nguyên giá</th>
              <th role="columnheader" class="text-center">Giá sale</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(puItem, puIndex) in promotionUids"
              :key="`pu_${puItem.id}_${puIndex}`"
              class="text-center"
            >
              <td>{{ puItem.barcode || `${puItem.sku}@${puItem.uid}` }}</td>
              <td>{{ puItem.customer_goods_barcode }}</td>
              <td>{{ formatNumber(puItem.price) }}</td>
              <td>{{ formatNumber(puItem.discount_value) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";
import { POS_PROMOTION_TYPES } from "@/libs/const";

export default {
  name: "PromotionDetail",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    posOptions: [],
    startDate: null,
    startTime: "00:00",
    endDate: null,
    endTime: "23:59",
    promotionBills: [],
    promotionProducts: [],
    promotionCombos: [],
    promotionUids: [],
    isLoading: false,
    goodsPriceMapping: {},
    goodsIdMapping: {},
    goods: [],
  }),
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    isDisabledBtn() {
      if (!this.item.pos_ids || this.item.pos_ids.length === 0) {
        return true;
      }
      if (!this.item.name || !this.item.promotion_type) {
        return true;
      }
      if (
        this.promotionBills.length === 0 &&
        this.promotionProducts.length === 0
      )
        if (!this.startTimeConverted || !this.endTimeConverted) {
          return true;
        }
      const start = moment(this.startTimeConverted).startOf("minute").unix();
      const end = moment(this.endTimeConverted).endOf("minute").unix();
      if (start > end) {
        return true;
      }
      return false;
    },
    startTimeConverted() {
      return !this.startDate || !this.startTime
        ? null
        : `${this.startDate} ${this.startTime}`;
    },
    endTimeConverted() {
      return !this.endDate || !this.endTime
        ? null
        : `${this.endDate} ${this.endTime}`;
    },
    promotionTypeOptions() {
      return POS_PROMOTION_TYPES;
    },
    discountTypeOptions() {
      return [
        {
          text: "Theo giá trị (đ)",
          color: "success--text",
          value: 1,
        },
        {
          text: "Theo phần trăm (%)",
          color: "primary--text",
          value: 2,
        },
        {
          text: "Giảm còn (đ)",
          color: "error--text",
          value: 3,
        },
      ];
    },
  },
  mounted() {
    const { poss } = window.me;
    this.posOptions = [...poss].map((w) => ({
      value: w.id,
      text: w.code || "",
    }));
    this.getItems();
  },
  methods: {
    async getItems() {
      const { data } = await httpClient.post("/pos-get-promotion-detail", {
        id: this.item.id,
      });
      if (this.item.promotion_type === 1) {
        this.promotionBills = [...data].map((d) => {
          const discountType = d.discount_left ? 3 : d.discount_percent ? 2 : 1;
          const discountTxt = this.discountTypeOptions.find(
            (dt) => dt.value === discountType
          );
          d.discount_type = discountType;
          d.discount_type_text = discountTxt.text;
          d.discount_value_text =
            discountType === 3
              ? `${this.formatNumber(d.discount_left)} đ`
              : discountType === 2
              ? `${d.discount_percent * 100} %`
              : `${this.formatNumber(d.discount_value)} đ`;
          return d;
        });
      } else if (this.item.promotion_type === 2) {
        this.promotionProducts = [...data].map((d) => {
          d.origin_price = d.discount_from * d.one_origin_price;
          d.total_price = d.discount_from * d.discount_value;
          d.discount_price = d.origin_price - d.total_price;
          d.discount_percent = 1 - d.total_price / d.origin_price;
          return d;
        });
      } else if (this.item.promotion_type === 3) {
        this.promotionCombos = [...data].map((d) => {
          let origin_price = 0;
          for (let di = 0; di < d.items.length; di++) {
            const dItem = d.items[di];
            origin_price += dItem.quantity * dItem.one_origin_price;
            console.log(origin_price);
          }
          d.origin_price = origin_price;
          d.discount_price = d.origin_price - d.discount_value;
          d.discount_percent = 1 - d.discount_value / d.origin_price;
          return d;
        });
      } else if (this.item.promotion_type === 4) {
        this.promotionUids = [...data];
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
